import loadable from '@loadable/component';

import { UserRole } from 'models/User';
import { ORG_SERVICE } from 'apis/organization/models';
import { _translate } from 'translate/TranslateProvider';

import { IconType } from '../components/atoms/SvgIcon';

const User = loadable(
  () => import(/* webpackChunkName: "Users" */ 'page/private/Users'),
);
const UserDetail = loadable(
  () => import(/* webpackChunkName: "UserDetail" */ 'page/private/UserDetail'),
);
const Organization = loadable(
  () =>
    import(/* webpackChunkName: "Organization" */ 'page/private/Organization'),
);
const Routers = loadable(
  () => import(/* webpackChunkName: "Routers" */ 'page/private/Routers'),
);
const VoiceLinkDetail = loadable(
  () =>
    import(/* webpackChunkName: "VoiceLinks" */ 'page/private/VoicelinkDetail'),
);
const RouterDetail = loadable(
  () =>
    import(/* webpackChunkName: "RouterDetail" */ 'page/private/RouterDetail'),
);
const Profile = loadable(
  () =>
    import(/* webpackChunkName: "UserProfile" */ 'page/private/UserProfile'),
);
const CaptivePortal = loadable(
  () =>
    import(
      /* webpackChunkName: "CaptivePortal" */ 'page/private/CaptivePortal'
    ),
);
const Showcase = loadable(
  () => import(/* webpackChunkName: "Showcase" */ 'page/private/Showcase'),
);
// const Support = loadable(
//   () => import(/* webpackChunkName: "Support" */ 'page/private/Support'),
// );
const NotAllow = loadable(
  () => import(/* webpackChunkName: "NotAllow" */ 'page/error/NotAllow'),
);
const CallHistory = loadable(
  () =>
    import(
      /* webpackChunkName: "CallHistory" */ 'page/private/RouterDetail/page/CallHistory'
    ),
);

const SuperAdmin = loadable(
  () => import(/* webpackChunkName: "SuperAdmin" */ 'page/private/SuperAdmin'),
);

const Subscription = loadable(
  () =>
    import(/* webpackChunkName: "Subscription " */ 'page/private/Subscription'),
);

const CaptiveCreationV2 = loadable(
  () =>
    import(
      /* webpackChunkName: "CaptiveCreationV2" */ 'page/private/CaptivePortal/page/CaptiveCreationV2'
    ),
);

const UserGuide = loadable(
  () => import(/* webpackChunkName: "UserGuide" */ 'page/private/UserGuide'),
);
const UserGuideSearch = loadable(
  () =>
    import(
      /* webpackChunkName: "UserGuideSearch" */ 'page/private/UserGuideSearch'
    ),
);

const Developer = loadable(
  () => import(/* webpackChunkName: "Developer" */ 'page/private/Developer'),
);

const Subscriber = loadable(
  () => import(/* webpackChunkName: "Subscriber" */ 'page/private/Subscriber'),
);

const SubscriptionPlan = loadable(
  () =>
    import(
      /* webpackChunkName: "SubscriberSubscriptionPlan " */ 'page/private/SubscriptionPlan'
    ),
);

const StatusHistory = loadable(
  () =>
    import(
      /* webpackChunkName: "StatusHistory" */ 'page/private/RouterDetail/page/StatusHistory'
    ),
);

const SubscriptionSummary = loadable(
  () =>
    import(
      /* webpackChunkName: "SubscriptionSummary" */ 'page/private/SubscriptionSummary'
    ),
);

const BulkActionList = loadable(
  () =>
    import(
      /* webpackChunkName: "SubscriptionSummary" */ 'page/private/BulkActionList'
    ),
);

const BulkActionNew = loadable(
  () =>
    import(
      /* webpackChunkName: "SubscriptionSummary" */ 'page/private/BulkAction'
    ),
);

const VoiceLinksNew = loadable(
  () =>
    import(
      /* webpackChunkName: "SubscriptionSummary" */ 'page/private/VoicelinkNew'
    ),
);

export type MenuItem = {
  url: string;
  icon?: IconType;
  titleKey?: string;
  keys?: RegExp[];
  availableFor: Uppercase<UserRole>[];
  availableForService: Array<keyof typeof ORG_SERVICE> | 'ALL';
  component: React.ReactNode & { preload?(): void };
  showMenu: boolean;
  exact?: boolean;
  ariaLabel?: string;
};

export const DEFAULT_ROUTE = '/routers';

const routes: MenuItem[] = [
  // {
  //   url: '/home',
  //   icon: 'DASHBOARD',
  //   titleKey: 'DASHBOARD_MENU_TILE',
  //   keys: [/\/home$/],
  //   component: Home,
  //   showMenu: true,
  //   availableFor: [
  //     'ADMIN',
  //     'DEVICE_MANAGER',
  //     'DEVICE_VIEWER',
  //     'MANAGER',
  //     'VIEWER',
  //   ],
  // },
  {
    url: '/routers',
    icon: 'router_fill',
    titleKey: 'ROUTERS_MENU_TILE',
    keys: [/\/routers$/, /\/router\/.*/],
    component: Routers,
    showMenu: true,
    ariaLabel: _translate('WCAG.ACCESS_ROUTER_LIST'),
    availableFor: [
      'ADMIN',
      'DEVICE_MANAGER',
      'DEVICE_VIEWER',
      'MANAGER',
      'VIEWER',
    ],
    availableForService: ['ROUTER'],
  },
  {
    url: '/voicelink',
    icon: 'voicelink_fill',
    titleKey: 'VOICE_LINKS_MENU_TITLE',
    keys: [/\/voicelink$/, /\/voicelink\/.*/],
    component: VoiceLinksNew,
    showMenu: true,
    ariaLabel: _translate('WCAG.ACCESS_VOICELINK_LIST'),
    availableForService: ['VOICELINK'],
    availableFor: [
      'ADMIN',
      'DEVICE_MANAGER',
      'DEVICE_VIEWER',
      'MANAGER',
      'VIEWER',
    ],
  },
  {
    url: '/captives',
    component: CaptivePortal,
    showMenu: true,
    ariaLabel: _translate('WCAG.ACCESS_CAPTIVE_LIST'),
    icon: 'captive_portal',
    titleKey: 'CAPTIVE_PORTAL',
    keys: [/\/captives\/.*/, /\/captive\/.*/, /captive$/],
    availableFor: ['ADMIN', 'MANAGER', 'VIEWER'],
    availableForService: ['CAPTIVE'],
  },
  {
    url: '/users',
    icon: 'profile',
    titleKey: 'USERS_MENU_TILE',
    keys: [/^\/users$/, /\/user\/.*/],
    component: User,
    showMenu: false,
    availableForService: 'ALL',
    availableFor: ['ADMIN', 'MANAGER', 'VIEWER'],
  },
  {
    url: '/orgs',
    icon: 'organization',
    titleKey: 'MY_ORGANIZATION_MENU_TILE',
    keys: [/\/orgs$/],
    component: Organization,
    showMenu: false,
    availableFor: [
      'ADMIN',
      'MANAGER',
      'VIEWER',
      'DEVICE_MANAGER',
      'DEVICE_VIEWER',
    ],
    availableForService: 'ALL',
  },
  // Hide on menu
  {
    url: '/user/:id',
    component: UserDetail,
    showMenu: false,
    availableFor: ['ADMIN', 'MANAGER', 'VIEWER'],
    availableForService: 'ALL',
  },
  {
    url: '/profile',
    component: Profile,
    showMenu: false,
    availableForService: 'ALL',
    availableFor: [
      'ADMIN',
      'DEVICE_MANAGER',
      'DEVICE_VIEWER',
      'MANAGER',
      'VIEWER',
      'NO_ORGANIZATION',
      'ORGANIZATIONS_BLOCK',
    ],
  },
  {
    url: '/router/:id',
    component: RouterDetail,
    showMenu: false,
    availableForService: 'ALL',
    availableFor: [
      'ADMIN',
      'MANAGER',
      'VIEWER',
      'DEVICE_MANAGER',
      'DEVICE_VIEWER',
    ],
  },
  {
    url: '/router-bulk-action',
    component: BulkActionList,
    showMenu: false,
    availableForService: 'ALL',
    availableFor: ['ADMIN', 'SUPER_ADMIN'],
  },
  {
    url: '/router-bulk-action/new',
    component: BulkActionNew,
    showMenu: false,
    availableForService: 'ALL',
    availableFor: ['ADMIN', 'SUPER_ADMIN'],
  },
  {
    url: '/voicelink/:id',
    component: VoiceLinkDetail,
    showMenu: false,
    availableForService: ['VOICELINK'],
    availableFor: [
      'ADMIN',
      'MANAGER',
      'VIEWER',
      'DEVICE_MANAGER',
      'DEVICE_VIEWER',
    ],
  },
  {
    url: '/router/:id/history/:imei/:type/:key',
    component: StatusHistory,
    showMenu: false,
    availableFor: [
      'ADMIN',
      'DEVICE_MANAGER',
      'DEVICE_VIEWER',
      'MANAGER',
      'VIEWER',
    ],
    availableForService: 'ALL',
  },
  {
    url: '/voicelink/:id/history/:imei/:type/:key',
    component: StatusHistory,
    showMenu: false,
    availableFor: [
      'ADMIN',
      'DEVICE_MANAGER',
      'DEVICE_VIEWER',
      'MANAGER',
      'VIEWER',
    ],
    availableForService: 'ALL',
  },
  {
    url: '/router/:id/call-history/:imei',
    component: CallHistory,
    showMenu: false,
    availableFor: [
      'ADMIN',
      'DEVICE_MANAGER',
      'DEVICE_VIEWER',
      'MANAGER',
      'VIEWER',
    ],
    availableForService: ['VOICELINK'],
  },
  {
    url: '/voicelink/:id/call-history/:imei',
    component: CallHistory,
    showMenu: false,
    availableFor: [
      'ADMIN',
      'DEVICE_MANAGER',
      'DEVICE_VIEWER',
      'MANAGER',
      'VIEWER',
    ],
    availableForService: ['VOICELINK'],
  },
  {
    url: '/captive',
    component: CaptiveCreationV2,
    showMenu: false,
    availableFor: ['ADMIN', 'MANAGER'],
    availableForService: ['CAPTIVE'],
  },
  {
    url: '/captive/:id',
    component: CaptiveCreationV2,
    showMenu: false,
    availableFor: ['ADMIN', 'MANAGER'],
    availableForService: ['CAPTIVE'],
  },
  {
    url: '/component-showcase',
    component: Showcase,
    showMenu: false,
    availableFor: ['ADMIN', 'MANAGER'],
    availableForService: 'ALL',
  },
  {
    url: '/user-guide-search',
    component: UserGuideSearch,
    showMenu: false,
    exact: false,
    availableForService: 'ALL',
    availableFor: [
      'ADMIN',
      'MANAGER',
      'DEVICE_MANAGER',
      'DEVICE_VIEWER',
      'VIEWER',
      'NO_ORGANIZATION',
      'ORGANIZATIONS_BLOCK',
    ],
  },
  {
    url: '/user-guide',
    component: UserGuide,
    showMenu: false,
    exact: false,
    availableForService: 'ALL',
    availableFor: [
      'ADMIN',
      'MANAGER',
      'DEVICE_MANAGER',
      'DEVICE_VIEWER',
      'VIEWER',
      'NO_ORGANIZATION',
      'ORGANIZATIONS_BLOCK',
    ],
  },
  // {
  //   url: '/support',
  //   component: Support,
  //   showMenu: false,
  //   availableForService: 'ALL',
  //   availableFor: [
  //     'ADMIN',
  //     'MANAGER',
  //     'DEVICE_MANAGER',
  //     'DEVICE_VIEWER',
  //     'VIEWER',
  //     'NO_ORGANIZATION',
  //     'ORGANIZATIONS_BLOCK',
  //   ],
  // },
  {
    url: '/super-admin',
    component: SuperAdmin,
    showMenu: false,
    availableForService: 'ALL',
    availableFor: ['SUPER_ADMIN'],
    exact: false,
  },
  {
    url: '/developer',
    component: Developer,
    showMenu: false,
    availableForService: 'ALL',
    availableFor: ['DEVELOPER'],
    exact: false,
  },
  // {
  //   url: '/super-admin/import-router',
  //   component: SuperAdmin,
  //   showMenu: false,
  //   availableFor: ['SUPER_ADMIN'],
  // },
  // {
  //   url: '/super-admin/user-guide',
  //   component: UserGuide,
  //   showMenu: false,
  //   availableFor: ['SUPER_ADMIN'],
  // },
  {
    url: '/super-admin/user-guide',
    component: SuperAdmin,
    showMenu: false,
    availableForService: 'ALL',
    availableFor: ['SUPER_ADMIN'],
  },
  {
    url: '/subscription',
    component: Subscription,
    showMenu: false,
    availableForService: 'ALL',
    availableFor: ['SUPER_ADMIN', 'ADMIN'],
  },
  {
    url: '/subscription-plan',
    component: SubscriptionPlan,
    showMenu: false,
    availableForService: 'ALL',
    availableFor: ['SUPER_ADMIN'],
  },
  {
    url: '/subscriber',
    component: Subscriber,
    showMenu: false,
    availableForService: 'ALL',
    availableFor: ['SUPER_ADMIN', 'ADMIN'],
  },
  {
    url: '/subscription-summary',
    component: SubscriptionSummary,
    showMenu: false,
    availableForService: 'ALL',
    availableFor: ['SUPER_ADMIN', 'ADMIN'],
  },
  {
    url: '/not-allow',
    component: NotAllow,
    showMenu: false,
    availableForService: 'ALL',
    availableFor: ['NO_ORGANIZATION', 'ORGANIZATIONS_BLOCK'],
  },
];

export default routes;
