import React, { useCallback } from 'react';
import { Layout } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import logo from 'assets/images/logo.svg';
import { popoverStyle } from 'theme/popoverStyle';
import { reset } from 'features/users/detail';
import AppLogo from 'components/atoms/AppLogo';
import Block from 'components/atoms/Block';
import IconButton from 'components/molecules/IconButton';
import UserAvatar from 'components/molecules/UserAvatar';
import Popover from 'components/molecules/Popover';
import Text from 'components/atoms/Text';
import TextOverFlow from 'components/molecules/TextOverFlow';
import { useTranslate } from 'translate/useTranslate';
import { DEFAULT_ROUTE } from 'layout/routes';
import { APP_ENVIRONMENT } from 'constants/configs';
import { useLogout } from 'hooks/useLogout';
import { currentUserState, selectors } from 'data/auth/currentUser';
import { theme } from 'theme/theme';
import { useGetTimeZone } from 'page/private/UserProfile/hooks/useGetTimeZones';
import AppBanner from 'modules/Banner';

import RolePermission from '../../atoms/RolePermission';

const { Header } = Layout;

const EnvironmentSignatureMapping = {
  DEVELOP: 'MESSAGE.ENVIRONMENT.DEV',
  STAGING: 'MESSAGE.ENVIRONMENT.STG',
} as Record<string, string>;

const StyledUserPopover = createGlobalStyle`
  .user-popover {
    ${popoverStyle};
    line-height: 1;

    .ant-popover-inner {
      .ant-popover-inner-content {
        padding: 12px 0px 10px 0px;
        width: 260px;
        & > *:first-child {
          max-height: unset;
        }
      }
    }

    padding-top: 5px;
  }
`;

const StyledHeader = styled(Header)`
  background: ${(p) => p.theme.colors.base_50};
  padding: 0;
  display: flex;
  flex-direction: column;
  height: auto;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1));
  box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.15);
`;

const SupportText = styled(Text)<{ $show: boolean }>`
  display: ${(p) => (p.$show ? 'initial' : 'none')};
  border-left: 1px solid ${theme.colors.grayscale_200};
  padding-left: 20px;
  margin-left: 20px;
`;

const EnvironmentText = styled(Text)`
  margin-left: 20px;
`;

const TimezoneText = styled(Text)`
  margin-right: 16px;
  color: ${theme.colors.grayscale_600};
  font-size: 14px;
`;

const StyledLoading = styled.div`
  height: 4px;
  min-height: 4px;
  background: linear-gradient(
      90deg,
      ${theme.colors.branding_500} 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    ${theme.colors.darkblue};
`;

const InfoBlock = styled(Block)`
  padding: 0px 8px 4px 16px;
`;

const UserNameText = styled(TextOverFlow)`
  margin-bottom: 4px;
`;

const UserEmailText = styled(TextOverFlow)`
  padding-bottom: 13px;
`;

const MenuItem = styled(Block)`
  padding: 6px 20px;
  margin-bottom: 6px;
`;

const MyProfileItem = styled(Block)`
  padding: 0px 20px 10px;
  border-bottom: 1px solid ${theme.colors.grayscale_200};
`;

const LogOutItem = styled(Block)`
  padding: 10px 20px;
  border-top: 1px solid ${theme.colors.grayscale_200};
`;

const StyledText = styled(Text)`
  font-size: 14px;
  line-height: 20px;
`;

const Logo = styled.div`
  cursor: pointer;
  display: flex;
`;

const AppHeader = (): JSX.Element => {
  const { t, currentLanguage } = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuth = currentUserState(selectors.selectIsAuth);
  const currentUser = currentUserState((s) => s);

  const [showMenu, setShowMenu] = React.useState(false);

  const { data: timezones } = useGetTimeZone();

  const { logout } = useLogout();

  const doLogout = useCallback(() => {
    logout();
  }, []);

  const gotoPage = useCallback(
    (path: string) => {
      if (history.location.pathname !== path) {
        dispatch(reset());
      }
      setShowMenu(false);
      history.push(path);
    },
    [history],
  );

  const PopOverContent = React.useMemo(
    () => (
      <>
        <Block flex alignItems="flex-start" className="mr-20 ml-20">
          <UserAvatar
            userName={currentUser?.name}
            userId={currentUser?.id}
            src={currentUser?.picture}
            size={34}
            fontSize={1.3}
          />
          <InfoBlock flexGrow={1} flex column>
            <UserNameText
              variant="h3"
              bold
              textContent={currentUser?.name}
              width={160}
            />
            <UserEmailText
              textContent={currentUser?.email}
              width={160}
              color="disabled"
            />
          </InfoBlock>
        </Block>
        <MyProfileItem>
          <StyledText
            onClick={() => gotoPage('/profile')}
            variant="link"
            color="primary"
          >
            {t('APP_HEADER.USER_MENU.PROFILE')}
          </StyledText>
        </MyProfileItem>
        <RolePermission availableFor={['ADMIN', 'MANAGER', 'VIEWER']}>
          <MenuItem className="mt-4">
            <StyledText
              onClick={() => gotoPage('/users')}
              variant="link"
              color="primary"
            >
              {t('APP_HEADER.USER_MENU.USERS')}
            </StyledText>
          </MenuItem>
        </RolePermission>
        <MenuItem>
          <StyledText
            onClick={() => gotoPage('/orgs')}
            variant="link"
            color="primary"
          >
            {t('APP_HEADER.USER_MENU.ORGS')}
          </StyledText>
        </MenuItem>
        <RolePermission availableFor={['SUPER_ADMIN', 'ADMIN']}>
          <MenuItem>
            <StyledText
              onClick={() => gotoPage('/subscription')}
              variant="link"
              color="primary"
            >
              {t('APP_HEADER.USER_MENU.SUBSCRIPTION_AGREEMENT')}
            </StyledText>
          </MenuItem>
          <MenuItem>
            <StyledText
              onClick={() => gotoPage('/subscriber')}
              variant="link"
              color="primary"
            >
              {t('APP_HEADER.USER_MENU.SUBSCRIBERS')}
            </StyledText>
          </MenuItem>
        </RolePermission>
        <RolePermission availableFor={['SUPER_ADMIN']}>
          <MenuItem>
            <StyledText
              onClick={() => gotoPage('/subscription-plan')}
              variant="link"
              color="primary"
            >
              {t('APP_HEADER.USER_MENU.SUBSCRIPTION_PLAN')}
            </StyledText>
          </MenuItem>
        </RolePermission>
        <RolePermission availableFor={['SUPER_ADMIN', 'ADMIN']}>
          <MenuItem>
            <StyledText
              onClick={() => gotoPage('/subscription-summary')}
              variant="link"
              color="primary"
            >
              {t('APP_HEADER.USER_MENU.SUBSCRIPTION_SUMMARY')}
            </StyledText>
          </MenuItem>
        </RolePermission>
        <RolePermission rule="every" availableFor={['SUPER_ADMIN', 'ADMIN']}>
          <MenuItem>
            <StyledText
              onClick={() => gotoPage('/router-bulk-action')}
              variant="link"
              color="primary"
            >
              {t('APP_HEADER.USER_MENU.ROUTER_BULK_ACTION')}
            </StyledText>
          </MenuItem>
        </RolePermission>
        <RolePermission availableFor={['SUPER_ADMIN']}>
          <MenuItem>
            <StyledText
              onClick={() => gotoPage('/super-admin/import-router')}
              variant="link"
              color="primary"
            >
              {t('APP_HEADER.USER_MENU.SUPER_ADMIN')}
            </StyledText>
          </MenuItem>
        </RolePermission>
        <RolePermission availableFor={['DEVELOPER']}>
          <MenuItem className="mb-4">
            <StyledText
              onClick={() => gotoPage('/developer/log')}
              variant="link"
              color="primary"
            >
              {t('APP_HEADER.USER_MENU.DEVELOPER')}
            </StyledText>
          </MenuItem>
        </RolePermission>
        <LogOutItem>
          <StyledText onClick={doLogout} variant="link" color="primary">
            {t('APP_HEADER.USER_MENU.SIGN_OUT')}
          </StyledText>
        </LogOutItem>
      </>
    ),
    [isAuth, currentUser, currentLanguage],
  );

  return (
    <>
      <StyledUserPopover />
      <StyledHeader>
        <div>
          <AppBanner />
        </div>
        <Block
          css={{
            height: 64,
            padding: '10px 20px',
          }}
          flex
          justifyContent="space-between"
        >
          <Block flex center>
            <Logo onClick={() => history.push(DEFAULT_ROUTE)}>
              <AppLogo logoUrl={logo} />
            </Logo>
            <SupportText
              $show={'/support' === location.pathname}
              variant="h1"
              color="gray-600"
            >
              {t('SUPPORT.TITLE')}
            </SupportText>
            {APP_ENVIRONMENT !== 'PRODUCTION' && (
              <EnvironmentText color="red" variant="h2">
                {t(EnvironmentSignatureMapping[APP_ENVIRONMENT as string])}
              </EnvironmentText>
            )}
          </Block>
          {isAuth && (
            <Block flex center>
              <TimezoneText>
                {
                  (timezones || []).find(
                    (t) => t.value === currentUser.timezone,
                  )?.label
                }
              </TimezoneText>
              <IconButton
                style={{
                  marginRight: 20,
                }}
                onClick={() => history.push('/user-guide')}
                icon="help"
                variant="secondary"
                size="medium"
                aria-label={t('WCAG.HELP_AND_USER_GUIDE')}
              />
              <Popover
                overlayClassName="user-popover"
                trigger="click"
                placement="bottomLeft"
                content={PopOverContent}
                visible={showMenu}
                onVisibleChange={(show: boolean) => setShowMenu(show)}
              >
                <Block>
                  <UserAvatar
                    userName={currentUser?.name}
                    userId={currentUser?.id}
                    src={currentUser?.picture}
                    size={44}
                    fontSize={1.1}
                  />
                </Block>
              </Popover>
            </Block>
          )}
        </Block>
      </StyledHeader>
      <StyledLoading />
    </>
  );
};
{
  /*  */
}
export default React.memo(AppHeader);
