/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Obj } from 'models/Object';
import { isASCII } from 'utils/strings';
import semver from 'semver';
import React from 'react';

type Rule =
  | 'required'
  | 'isEmail'
  | 'isNumeric'
  | 'isPhoneNumber'
  | 'isNotOnlyNumeric'
  | 'isWifiPasswordValid'
  | 'isSSIDValid'
  | 'isValidMACAddress'
  | 'isValidIPAddress'
  | 'isFQDN'
  | 'isValidVersion'
  | 'isValidUrl'
  | 'isValidLatitude'
  | 'isValidLongitude';

type FormItemValue = string | number | Obj;

const VALID_NUMBER = /^\d+$/;
const INVALID_SSID_CHARS = /[`\\[\]?$+"]/;
const VALID_MAC_ADDRESS = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
// base on SER-5409, only allow user to input octet value between 0-255 WITHOUT any leading zero
// e.g. 192.010.10.0 is not a valid IP address
const VALID_IP_ADDRESS = /^(?!0\d)(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(?!0\d)(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(?!0\d)(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(?!0\d)(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)$/;
const VALID_FQDN = /^[a-zA-Z0-9-]+$/; // Fully qualified domain name
const VALID_PHONE_NUMBER = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
// validation regex for email
const VALID_EMAIL_RECIPIENT = /^(?!.*[._+-]{2})([a-zA-Z0-9]{1,}[._+-]?){1,}[a-zA-Z0-9]{1,}$/;
const VALID_EMAIL_DOMAIN = /^(?!.*[.-]{2})((?:[a-zA-Z0-9][a-zA-Z0-9-]*|[a-zA-Z0-9])+\.)+[a-zA-Z0-9]+$/;
const VALID_EMAIL_IP = /(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/;
const VALID_EMAIL_IP_FORMAT = /([0-9]{1,3}\.)+[0-9]{1,3}$/;

// validation regex for input multiple IMEIs separated by commas or whitespace.
// valid input = "688"; "123 456"; "456,222"; "123,  456"
// invalid input = "123@"; "111,222,"
const VALID_MULTIPLE_IMEIS = /^(\d+([,\s\n]+\d+)*)?$/;

// https://cran.r-project.org/web/packages/rex/vignettes/url_parsing.html
export const VALID_URL_WITH_PROTOCOLS = new RegExp(
  '^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$',
);

const required = (v: FormItemValue): boolean => {
  return v !== undefined && v !== null && (v.toString() || '').trim() !== '';
};

const isEmail = (v: FormItemValue): boolean => {
  if (!v || v === '') {
    return true;
  }

  const email = v.toString().trim();
  let isValidation = false;

  if (email?.length == 0 || email?.length > 320) {
    return isValidation;
  }

  const emailSplit = email.toString().split('@', 2);
  if (emailSplit.length == 2) {
    // const labels = emailSplit[1].split('.');
    const recipientValue = emailSplit[0];
    const domainValue = emailSplit[1];
    const isRecipientValid = recipientValue.length <= 64;
    const isDomainNameValid = domainValue.length <= 255;
    let isLabelInDomainNameValid = true;

    if (isRecipientValid && isDomainNameValid) {
      const labels = domainValue.split('.');

      if (labels.length > 127) {
        // Validate the maximum labels
        return isValidation;
      }

      if (labels.length == 4) {
        // Validate IP address
        let isAllNumberValid = true;
        for (const label in labels) {
          // Check all label is number
          if (!parseInt(label)) {
            isAllNumberValid = false;
          }
        }
        if (isAllNumberValid) {
          const isIPAddressFormat = VALID_EMAIL_IP_FORMAT.test(domainValue);
          if (isIPAddressFormat) {
            return VALID_EMAIL_IP.test(domainValue);
          }
          return false;
        }
      }

      const topLevel = labels[labels.length - 1]; // Validate the top domain level
      if (topLevel.length < 2 || topLevel.length > 63) {
        return isValidation;
      }

      for (const label of labels) {
        const labelLength = label.length;
        if (labelLength < 2 || labelLength > 63) {
          isLabelInDomainNameValid = false;
          break;
        }
      }
      isValidation =
        isRecipientValid &&
        isDomainNameValid &&
        isLabelInDomainNameValid &&
        VALID_EMAIL_RECIPIENT.test(recipientValue) &&
        VALID_EMAIL_DOMAIN.test(domainValue);
    }
  }
  return isValidation;
};

const isPhoneNumber = (v: FormItemValue): boolean => {
  if (!v || v === '') {
    return true;
  }

  if (typeof v !== 'string') {
    return false;
  }

  return VALID_PHONE_NUMBER.test(v);
};

export const isValidMACAddress = (v: FormItemValue) => {
  if (typeof v !== 'string') {
    return false;
  }

  return VALID_MAC_ADDRESS.test(v);
};

export const isWifiPasswordValid = (v: FormItemValue): boolean => {
  if (v === undefined || v === null) {
    return false;
  }

  if (typeof v !== 'string') {
    return false;
  }
  // Only printable characters plus the space (ASCII 0x20) are allowed.
  if (!isASCII(v)) {
    return false;
  }

  // Length must between 8 ~ 63 characters
  return 8 <= v.length && v.length <= 63;
};

export const isSSIDValid = (v: FormItemValue): boolean => {
  v = `${v}`.trim();

  if (v === undefined || v === null) {
    return false;
  }

  if (typeof v !== 'string') {
    return false;
  }

  // Only printable characters plus the space (ASCII 0x20) are allowed.
  if (!isASCII(v)) {
    return false;
  }

  // Trailing and leading spaces (ASCII 0x20) are not permitted.
  if (v.startsWith(' ') || v.endsWith(' ')) {
    return false;
  }

  // These six characters are not allowed: ?, ", $, [, \, ], and +.
  if (INVALID_SSID_CHARS.test(v)) {
    return false;
  }

  // Length must between 1 ~ 32 characters
  return 1 <= v.length && v.length <= 32;
};

export const isValidIPAddress = (v: FormItemValue): boolean => {
  if (v) {
    return VALID_IP_ADDRESS.test(v as string);
  }
  return true;
};

const isNumeric = (v: FormItemValue): boolean => {
  if (v) {
    return VALID_NUMBER.test(v as string);
  }
  return true;
};

const isNotOnlyNumeric = (v: FormItemValue): boolean => {
  if (v) {
    return !VALID_NUMBER.test(v as string);
  }
  return true;
};

const isFQDN = (v: FormItemValue): boolean => {
  if (typeof v !== 'string') {
    return false;
  }
  if (v.startsWith('-') || v.endsWith('-')) {
    return false;
  }
  if (v) {
    return VALID_FQDN.test(v);
  }
  return true;
};

export const isValidVersion = (v: FormItemValue): boolean => {
  if (v) {
    return (
      Boolean(semver.valid(v.toString())) && semver.gte(v.toString(), '2.6.1')
    );
  }
  return true;
};

export const isValidUrl = (v: FormItemValue): boolean => {
  if (v) {
    //check if url contain protocol
    const hasProtocol = (v as string).startsWith('http');
    const _v = hasProtocol ? v : `http://${v}`;
    return VALID_URL_WITH_PROTOCOLS.test(_v as string);
  }
  return true;
};

export const isValidLatitude = (v: FormItemValue): boolean => {
  if (typeof v !== 'string' && typeof v !== 'number') {
    return false;
  }

  if (v < -90) {
    return false;
  }

  if (v > 90) {
    return false;
  }

  return true;
};

export const isValidLongitude = (v: FormItemValue): boolean => {
  if (typeof v !== 'string' && typeof v !== 'number') {
    return false;
  }

  if (v < -180) {
    return false;
  }

  if (v > 180) {
    return false;
  }

  return true;
};

export const isValidInputMultipleIMEIS = (v: FormItemValue): boolean => {
  return VALID_MULTIPLE_IMEIS.test(v as string);
};

type RuleMap = Record<Rule, (_value: FormItemValue) => boolean>;
type ValidationRule = Partial<Record<Rule, string | React.ReactNode | null>>;

const ruleMapping: RuleMap = {
  required,
  isEmail,
  isNumeric,
  isPhoneNumber,
  isNotOnlyNumeric,
  isSSIDValid,
  isWifiPasswordValid,
  isValidMACAddress,
  isValidIPAddress,
  isFQDN,
  isValidVersion,
  isValidUrl,
  isValidLatitude,
  isValidLongitude,
};

const checkRule = (value: FormItemValue, ruleName: Rule): boolean => {
  return ruleMapping[ruleName](value);
};

const validate = (
  value: string | number | Obj<unknown>,
  rules: ValidationRule,
) => {
  for (const rule in rules) {
    if (!checkRule(value, rule as Rule)) {
      return Promise.reject(rules[rule as Rule]);
    }
  }
  return Promise.resolve();
};

export { validate, isEmail, isPhoneNumber, isNumeric };
